<template>
  <transition name="modal">
    <div
      v-if="imgIndex !== null"
      class="vgs"
      @click="close"
    >
      <button
        type="button"
        class="vgs__close"
        @click="close"
      >
        &times;
      </button>
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__prev"
        @click.stop="onPrev"
      >
        &lsaquo;
      </button>
      <div
        v-if="images"
        class="vgs__container"
        @click.stop="onNext"
      >
        <img
          class="vgs__container__img"
          :src="imageUrl"
          :alt="alt"
          @click.stop="onNext"
        >
        <slot></slot>
      </div>
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__next"
        @click.stop="onNext"
      >
        &rsaquo;
      </button>
      <div
        v-if="isMultiple"
        ref="gallery"
        class="vgs__gallery"
      >
        <div
          v-if="images"
          class="vgs__gallery__title"
        >
          {{ imgIndex + 1 }} / {{ images.length }}
        </div>
        <div
          v-if="images"
          class="vgs__gallery__container"
          :style="{ transform: 'translate(' + galleryXPos + 'px, 0)' }"
        >
          <img
            v-for="(img, i) in images"
            :key="i"
            class="vgs__gallery__container__img"
            :src="typeof img === 'string' ? img : img.url"
            :class="{ 'vgs__gallery__container__img--active': i === imgIndex}"
            :alt="typeof img === 'string' ? '' : img.alt"
            @click.stop="onClickThumb(img, i)"
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    images : {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      imgIndex: this.index,
      image: null,
      galleryXPos: 0,
      thumbnailWidth: 120
    };
  },
  computed: {
    imageUrl() {
      const img = this.images[this.imgIndex];
      if (typeof img === "string") {
          return img;
      }
      return img.url;
    },
    alt() {
      const img = this.images[this.imgIndex];
      if (typeof img === "object") {
          return img.alt;
      }

      return "";
    },
    isMultiple() {
      return this.images.length > 1;
    }
  },
  watch: {
    index(val, prev) {
      this.imgIndex = val;

      // updateThumbails when popup
      if (prev == null && val != null) {
        this.$nextTick(() => {
          this.updateThumbails();
        });
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.keyCode === 37) {
        this.onPrev();
      } else if (e.keyCode === 39) {
        this.onNext();
      } else if (e.keyCode === 27) {
        this.close();
      }
    });
  },
  methods: {
    close() {
      const eventData = {
        imgIndex: this.imgIndex
      };
      this.imgIndex = null;
      this.$emit("close", eventData);
    },
    onPrev() {
      if (this.imgIndex === null) return;
      if (this.imgIndex > 0) {
        this.imgIndex--;
      } else {
        this.imgIndex = this.images.length - 1;
      }
      this.updateThumbails();
    },
    onNext() {
      if (this.imgIndex === null) return;
      if (this.imgIndex < this.images.length - 1) {
        this.imgIndex++;
      } else {
        this.imgIndex = 0;
      }
      this.updateThumbails();
    },
    onClickThumb(image, index) {
      this.imgIndex = index;
      this.updateThumbails();
    },
    updateThumbails() {
      if (!this.$refs.gallery) {
        return;
      }

      this.$emit("slide", this.imgIndex);

      const galleryWidth = this.$refs.gallery.clientWidth;
      const currThumbsWidth = this.imgIndex * this.thumbnailWidth;
      const maxThumbsWidth = this.images.length * this.thumbnailWidth;
      const centerPos =
        Math.floor(galleryWidth / (this.thumbnailWidth * 2)) *
        this.thumbnailWidth;

      // Prevent scrolling of images if not needed
      if (maxThumbsWidth < galleryWidth) {
        return;
      }

      if (currThumbsWidth < centerPos) {
        this.galleryXPos = 0;
      } else if (
        currThumbsWidth >
        this.images.length * this.thumbnailWidth - galleryWidth + centerPos
      ) {
        this.galleryXPos = -(
          this.images.length * this.thumbnailWidth -
          galleryWidth
        );
      } else {
        this.galleryXPos = -(this.imgIndex * this.thumbnailWidth) + centerPos;
      }
    }
  }
};
</script>
